import { Injectable } from '@angular/core';
import { Permissions } from './permission.interface';

@Injectable({
  providedIn: 'root',
})
export class ProfileService {
  public loginToken: string;
  public userProfileData: any;
  user_permissions: Permissions;

  constructor() {}
  // set & get token
  public settoken(token: string) {
    localStorage.setItem('access_token', token);
    this.loginToken = token;
  }
  public gettoken() {
    return this.loginToken;
  }
  // set & get user profile data
  public setuserProfileData(data: any) {
    this.userProfileData = data;

    //localStorage.setItem('userProfileData', JSON.stringify(data));
  }
  public getuserProfileData() {
    return this.userProfileData;
    //return JSON.parse(localStorage.getItem('userProfileData') || '{}');
  }

  getUserName() {
    return this.userProfileData.username;
  }

  setPermissions() {
    if (
      localStorage.getItem('user_permissions') != 'undefined' &&
      localStorage.getItem('user_permissions') != 'null'
    ) {
      this.user_permissions = JSON.parse(
        localStorage.getItem('user_permissions') || '{}'
      );
      console.log('permissions', this.user_permissions);
    }
  }
}
