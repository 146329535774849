
<p-dialog [modal]="true" [breakpoints]="{'960px': '75vw', '640px': '100vw'}" [visible]="visibility" [draggable]="false" [classList]="'filter_dialog'" [position]="'right'"
    [resizable]="false" [closable]="false">

    <ng-template pTemplate="header">
        <div class="dialogHeader flex align-items-center justify-content-between wd-100 pl-2">
            <p>History</p>
            <p-button icon="pi pi-times" (onClick)="hide()" styleClass="p-button-text p-button-rounded"></p-button>
        </div>
    </ng-template>

    <p-scrollPanel [className]="'filter_scrollPanel'">
            
    <!-- <div class="card" > -->
        <ng-container *ngFor="let event of events">
            <!-- <p-timeline [value]="event" class="w-full md:w-20rem">
                <ng-template pTemplate="marker" let-eve>
                    <span class="eventicon">
                    </span>
                </ng-template>
                <ng-template pTemplate="content" let-eve>
                    <div class="flex flex-column">
                        <div class="flex align-items-center justify-content-between gap-4">
                            <div [ngClass]="eve.sub_title ? 'title_font' : 'maintitle_font'">{{eve.title}}</div>
                            <div *ngIf="eve.color" class="status_font" [ngStyle]="{borderColor: eve.color}">{{eve.status}}</div>
                        </div>
                     
                     <div class="subtitle_font">{{eve.sub_title}}</div>
                    </div>
                </ng-template>
            </p-timeline> -->
            <div class="timeline-wrapper">
                <div class="timeline">
                  <div class="container" *ngFor="let content of event">
                    <!-- <label class="mb-4"></label> -->
                    <div class="content" >
                        <div class="flex flex-column">
                            <div class="flex align-items-center justify-content-between gap-4">
                                <label class="mb-1"><span [ngClass]="content.sub_title ? 'title_font' : 'maintitle_font'">{{ content.title }}</span></label>
                                <div *ngIf="content.color" class="status_font" [ngStyle]="{borderColor: content.color}">{{content.status}}</div>
                            </div>
                         
                         <div class="subtitle_font">{{content.sub_title}}</div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
        </ng-container>


    <!-- </div> -->
    </p-scrollPanel>

    <ng-template pTemplate="footer">
        <div class="filter_dialogFooter flex align-items-center justify-content-end wd-100">
            <p-button type="button" label="Close" class="p-button-text elevated-btn primary" (onClick)="hide()"></p-button>
        </div>
    </ng-template>
</p-dialog>
