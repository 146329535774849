import { Component, EventEmitter, Output } from '@angular/core';
import { DialogService } from 'primeng/dynamicdialog';

@Component({
  selector: 'acv-grid-count-dialog',
  templateUrl: './grid-count-dialog.component.html',
  styleUrls: ['./grid-count-dialog.component.scss']
})
export class GridCountDialogComponent {
  title: string = "";
  show_Dialog: boolean = false;
  dialog_header: string = "Milestone Associated";
  data: any[] = [];
  data_type: string = "list";

  gridColumns = [
    { field: 'roles_name', header: 'Roles', visible: true, isClickable: false, align: 'center', colWidth: '50px' },
  ];

  @Output() Submit_Clicked: EventEmitter<any> = new EventEmitter();

  constructor(public dialogService: DialogService) {

  }

  cancel() {
    this.dialogService.dialogComponentRefMap.forEach(dialog => {
      dialog.destroy();
    });
  }


  show() {
    this.show_Dialog = true;
  }

  hide() {
    this.show_Dialog = false;
  }
}
