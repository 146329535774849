import {
  AfterContentChecked,
  AfterViewInit,
  ChangeDetectorRef,
  Component,
  ElementRef,
  Input,
  OnInit,
  ViewChild,
} from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { ApiHttpService } from 'src/app/core/services/apiHttpcall.service';

@Component({
  selector: 'acv-text-input',
  templateUrl: './text-input.component.html',
  styleUrls: ['./text-input.component.scss'],
})
export class TextInputComponent
  implements OnInit, AfterViewInit, AfterContentChecked
{
  value: any;
  wordCount: any;
  form_data: any;
  Options: any[];
  options_list: any[];
  @Input() form: FormGroup;
  @Input() ControlName: string;
  @Input() ControlType: string = 'text';
  @Input() Required: boolean = false;
  @Input() ControlLabelText: string;
  @Input() PlaceHolderText: string = 'Enter Here';
  @Input() minDate: Date;
  @Input() maxDate: Date;
  @Input() selectionMode: string = 'single';
  @Input() IconClass: string;
  @Input() IconPosition: string;
  @Input() ClassList: string;
  @Input() ShowWordCount: boolean = true;
  @Input() characterLimit: number = 50;
  @Input() IsSubmited: boolean = false;
  @Input() ControlNameCode: string;
  @Input() PlaceHolderTextCode: string = '+1';
  @Input() IsLoading: boolean = false;
  @Input() ExemptMaxLength: boolean = false;
  @Input() radioList: any[] = [];
  cc_loader: boolean = true;
  cc_list: any[] = [];
  @Input('Options') set _Options(value: any) {
    this.Options = value ? value : [];
    this.options_list = this.Options;
  }
  @Input() PatternErrorMessage: string;

  @ViewChild('textControl') text: ElementRef;

  words: number = 0;
  phone_value: any;

  constructor(
    private cdref: ChangeDetectorRef,
    private httpService: ApiHttpService
  ) {}

  ngOnInit(): void {
    this.form_data = this.form.value;
    this.options_list = this.Options;
    if(this.ControlType == 'phoneCode'){
      this.loadCountryCodes()
    }
  }

  ngAfterViewInit(): void {
    this.form.controls[this.ControlName].setValue(
      this.form_data[this.ControlName]
    );
    if (this.ShowWordCount) {
      this.wordCounter();
    }
    this.cdref.detectChanges();
  }

  ngAfterContentChecked() {
    this.cdref.detectChanges();
  }

  async loadCountryCodes() {
    this.cc_loader = true;
    let resp = await this.httpService.get('/countries');
    this.cc_loader = false;
    if (resp.status == 200 || resp.status == 201) {
      this.cc_list = resp?.data;
      console.log(this.cc_list,'fghjkl');
    }
  }

  wordCounter() {
    //alert(this.text.nativeElement.value)
    this.wordCount = this.text
      ? this.text?.nativeElement?.value.split(/\s+/)
      : 0;
    this.words = this.text?.nativeElement?.value.length;
  }

  onPhoneCodeChange(e: any) {
    console.log(e);
  }

  getSelectedCountry(country_code: string) {
    return this.cc_list.find((x: any) => x.phonecode == country_code);
  }
}
