<acv-grid-count-dialog #ACV_GridCountDialog_Ref></acv-grid-count-dialog>

<div class="grid_parent">
    <!-- <div class="grid_wrapper" [ngClass]="grid_columns?.length > 5 ? 'acv-grid-scroll' : 'acv-grid-fixedcol'"></div> -->
    <div class="grid_wrapper">
        <p-table #DigicomGrid [columns]="grid_columns" [value]="grid_data" [scrollable]="true" [lazy]="true" sortMode="single"
            [reorderableColumns]="allow_columnReOrder" [scrollHeight]="'100%'" [(selection)]="selectedRecords"
            (onHeaderCheckboxToggle)="onSelectAll($event)" (selectionChange)="onSelectionChange($event)"
            (onSort)="onSortChange($event)">
            <ng-template pTemplate="header" let-columns>
                <tr>
                    <th *ngIf="allow_selection" style="width: 3rem !important" class="text-center">
                        <p-tableHeaderCheckbox *ngIf="allow_Headerselection"></p-tableHeaderCheckbox>
                    </th>
                    <ng-container *ngFor="let col of columns">

                        <th *ngIf="allow_columnReOrder"
                            [pSortableColumn]="(allow_sorting && !hold_gridSorting) ? col.field : ''" pReorderableColumn
                            [ngClass]="col.styleClass ? col.styleClass : ''" [class.hold-gridSorting]="hold_gridSorting"
                            [ngStyle]="{'text-align':'center', 'width': col.colWidth ? col.colWidth : null}">
                            {{col.header}}
                            <p-sortIcon *ngIf="allow_sorting && col.header && !hold_gridSorting"
                                field="{{col.field}}"></p-sortIcon>
                        </th>

                        <th *ngIf="!allow_columnReOrder"
                            [pSortableColumn]="(allow_sorting && !hold_gridSorting) ? col.field : ''"
                            [ngClass]="col.styleClass ? col.styleClass : ''"
                            [ngStyle]="{'text-align':col.align, 'width': col.colWidth ? col.colWidth : null}">
                            {{col.header}}<p-sortIcon *ngIf="allow_sorting && !hold_gridSorting"
                                field="{{col.field}}"></p-sortIcon>
                        </th>
                    </ng-container>

                    <ng-container *ngIf="freeze_actionColumn">
                        <th class="text-center action-th" style="width: 1rem" *ngIf="show_livelevelActions"
                            alignFrozen="right" pFrozenColumn> <i class="pi i-reorder" *ngIf="reorderableColumns"
                                (click)="columnReorderToggle()"></i></th>
                    </ng-container>

                    <ng-container *ngIf="!freeze_actionColumn">
                        <th class="text-center action-th" style="width: 1rem" *ngIf="show_livelevelActions"><i
                                class="pi i-reorder" *ngIf="reorderableColumns" (click)="columnReorderToggle()"></i>
                        </th>
                    </ng-container>
                </tr>
            </ng-template>

            <ng-template pTemplate="groupheader" let-data *ngIf="show_RowGroup">
                <tr pRowGroupHeader>
                    <td colspan="5">
                        <span class="font-bold ml-2">{{data.row_group}}</span>
                    </td>
                </tr>
            </ng-template>

            <ng-template pTemplate="body" let-rowData let-columns="columns">
                <tr>
                    <td *ngIf="allow_selection" class="text-center">
                        <p-tableCheckbox [value]="rowData"></p-tableCheckbox>
                    </td>
                    <ng-container *ngFor="let col of columns">

                        <ng-container *ngIf="col.field == 'status' || col.field == 'is_active'; else noStatusCol">
                            <td [attr.status]="rowData['order_type']"
                                [ngClass]="{'active-button':(rowData[col.field]?.toLowerCase() == 'active' || rowData[col.field] == true), 'inactive-button': (rowData[col.field]?.toLowerCase() == 'inactive' || rowData[col.field] == false)}"
                                [ngStyle]="{'text-align':col.align}">
                                <div class="flex flex-row justify-content-center">
                                    <button style="cursor: default;" pButton [attr.status]="rowData[col.field]"
                                        type="button"
                                        label="{{col.field == 'is_active' ? 
                                        (rowData[col.field]?.toLowerCase() == 'active' || rowData[col.field] == true) ? 'Active' : 'Inactive' :  
                                        rowData[col.field] ? rowData[col.field] :'-'}}"
                                        class="btn-status p-button-outlined"></button>
                                </div>
                            </td>
                        </ng-container>

                        <ng-template #noStatusCol>

                            <ng-container *ngIf="col.fieldType == 'input'; else dateCol">
                                <td [ngStyle]="{'text-align':col.align}">
                                    <input type="text" [ngClass]="col.inputStyleClass ? col.inputStyleClass : ''"
                                        pInputText [(ngModel)]="rowData[col.field]" placeholder="-" />
                                </td>
                            </ng-container>
                            <ng-template #dateCol>
                                <ng-container *ngIf="col.fieldType == 'date'; else noInputCol">
                                    <td [ngStyle]="{'text-align':col.align}">
                                        <p [(ngModel)]="rowData[col.field]" placeholder="-" [name]="rowData[col.field]"
                                            ngDefaultControl>
                                            {{rowData[col.field] ? (rowData[col.field] | date : col.format) : '-'}}
                                        </p>
                                    </td>
                                </ng-container>
                            </ng-template>
                            <ng-template #noInputCol>
                                <ng-container *ngIf="allowHighlightSearching; else nohighlightSearching">
                                    <td [ngStyle]="{'text-align':col.align, 'text-decoration':col.externalLink && 'underline'}"
                                        (click)="(col.isClickable || col.externalLink) && oncellClicked(rowData)"
                                        [ngClass]="[col.classList ? col.classList : '']">
                                        <p [style]="{'text-transform': col.capitalize ? 'capitalize' : '', 'cursor':(col.isClickable || col.externalLink) && 'pointer'}"
                                            [ngClass]="col.allowHiglight && rowData['is_failed'] && rowData['failed_field'] == col.field ? 'highlight_text' : ''"
                                            [innerHtml]="(rowData[col.field] || rowData[col.field] == 0) ? (rowData[col.field] | highlighter:highlightedSearchItems:'full') : '-' ">
                                            <i *ngIf="col.externalLink" class="pi i-arrow-topR"
                                                style="cursor: pointer;"></i>
                                        </p>
                                    </td>
                                </ng-container>
                                <ng-template #nohighlightSearching>
                                    <td [ngStyle]="{'text-align':col.align, 'text-decoration':col.externalLink && 'underline'}"
                                        (click)="(col.isClickable || col.externalLink) && oncellClicked(rowData)"
                                        [ngClass]="[col.classList ? col.classList : '']">

                                        <ng-container *ngIf="col?.colCount">
                                            <ng-container *ngIf="rowData[col.field].length">
                                                <div class="flex align-items-center justify-content-center gap-2">
                                                    <p (click)="onCountClick(col, rowData)" 
                                                    [style]="{'font-size': '12px', 'text-decoration': 'underline', 'cursor': 'pointer'}">
                                                        {{(rowData[col.field].length).toString()}}</p>
                                                    <!-- <p>{{rowData[col.field][0] | slice:0:4 }}</p>
                                                    <p style="color: #96444B; letter-spacing: 1px;" class="fw-500 fs-15"
                                                        >...</p>
                                                    <p-tag icon="pi ic-plus"
                                                        [ngClass]="{'small-font': (rowData[col.field].length - 1).toString().length>1}"
                                                        [rounded]="true"
                                                        (click)="onCountClick(col.field, rowData)"
                                                        [value]="(rowData[col.field].length - 1).toString()"></p-tag> -->
                                                </div>
                                            </ng-container>

                                            <p *ngIf="rowData[col.field].length == 0">-</p>
                                        </ng-container>

                                        <ng-container *ngIf="col?.progressbar">
                                            <div class="progress-wrapper">
                                                <round-progress [current]="rowData[col.field]" [max]="100" [color]="rowData[col.field] > 0 && rowData[col.field] < 100 ? '#F2D32E' : '#35B109'" [background]="'#eaeaea'" [radius]="22" [stroke]="8"
                                                [semicircle]="false" [rounded]="false" [clockwise]="false" [responsive]="false" [duration]="800"
                                                [animation]="'easeInOutQuart'" [animationDelay]="1800"></round-progress>
                                                <div class="progress-value">{{rowData[col.field] ? rowData[col.field] : '0'}}%</div>
                                            </div>
                                        </ng-container>

                                        <ng-container *ngIf="!col?.colCount && !col?.progressbar">
                                            <div class="flex gap-2" [ngClass]="col.align == 'center' ? 'justify-content-center' : 'justify-content-start'">
                                                <p [pTooltip]=" rowData[col.field]?.length > 15 ? rowData[col.field] : ''"
                                                    tooltipPosition="right" [ngClass]="col.isClickable == true ? 'cursor-pointer' : ''">
                                                    {{
                                                    (rowData[col.field] || rowData[col.field] === 0) ?
                                                    (rowData[col.field].length > 15 ? (rowData[col.field] | slice:0:15)
                                                    :
                                                    rowData[col.field]) : '-'
                                                    }}
                                                </p>
                                                <p style="color: #96444B; letter-spacing: 1px;" class="fw-500 fs-15"
                                                    *ngIf="rowData[col.field]?.length > 15"
                                                    [pTooltip]="rowData[col.field]" tooltipPosition="right">
                                                    ...
                                                </p>
                                            </div>
                                        </ng-container>
                                    </td>
                                </ng-template>
                            </ng-template>
                        </ng-template>
                    </ng-container>

                    <ng-container *ngIf="freeze_actionColumn">
                        <td class="text-center frozen-column"
                            *ngIf="show_livelevelActions || show_EditIcon || show_DeleteIcon" alignFrozen="right"
                            pFrozenColumn>
                            <div class="grid_actions">
                                <ng-container *ngTemplateOutlet="actionContent"></ng-container>
                            </div>

                        </td>
                    </ng-container>

                    <ng-container *ngIf="!freeze_actionColumn">
                        <td *ngIf="show_livelevelActions || show_EditIcon">
                            <div class="grid_actions">
                                <ng-container *ngTemplateOutlet="actionContent"></ng-container>
                            </div>
                        </td>
                    </ng-container>

                    <ng-template #actionContent>
                        <!-- <ng-container *ngIf="show_livelevelActions">
                            <p-splitButton appendTo="body" [model]="line_levelActions" menuStyleClass="grid-actionMenu"
                                styleClass="p-button-text btn-action"></p-splitButton>
                        </ng-container> -->

                        <ng-container *ngIf="show_livelevelActions">
                            <!-- <p-dropdown #actionDropdown [options]="getLineActionItems(rowData)" [appendTo]="'body'" [autoDisplayFirst]="false"
                                optionLabel="label" styleClass="p-button-text btn-action"
                                (onChange)="onLineAction($event, rowData); actionDropdown.updateSelectedOption(null)" [showClear]="false" placeholder=""
                                [panelStyleClass]="'grid-action-panel'"
                                [overlayOptions]="{styleClass: 'gridoverlay-content-panel'}">
                                <ng-template pTemplate="selectedItem"></ng-template>
                                <ng-template let-action pTemplate="item">
                                    <ng-container
                                        *ngIf="action?.label.toLowerCase() == 'active' || action?.label.toLowerCase() == 'inactive'; else nonstatus">
                                        <div>{{ (rowData.status == 'active' || rowData.status == 'Active' ||
                                            rowData?.is_active) ? 'Inactive' : 'Active' }}</div>
                                    </ng-container>
                                    <ng-template #nonstatus>
                                        <div>{{ action?.label }}</div>
                                    </ng-template>
                                </ng-template>
                            </p-dropdown> -->
                            <p-splitButton appendTo="body" [model]="getLineActionItems(rowData)" menuStyleClass="grid-actionMenu"
                            styleClass="p-button-text btn-action" (onDropdownClick)="active_row_data = rowData" [panelStyleClass]="'grid-action-panel'"
                            [overlayOptions]="{styleClass: 'gridoverlay-content-panel'}"></p-splitButton>
                        </ng-container>

                        <ng-container *ngIf="show_EditIcon">
                            <i class="pi i-Edit" (click)="onGridAction('Edit', rowData)"></i>
                        </ng-container>

                        <ng-container *ngIf="show_DeleteIcon">
                            <i class="pi i-trash-bin" (click)="onGridAction('Delete', rowData)"></i>
                        </ng-container>

                        <ng-container *ngIf="show_AttachmentIcon">
                            <i class="pi i-attachment" (click)="onGridAction('Edit', rowData)"></i>
                        </ng-container>

                    </ng-template>
                </tr>
            </ng-template>

            <ng-template pTemplate="emptymessage">
                <tr>
                    <td [attr.colspan]="grid_columns.length + 2" class="text-center p-3">No records found.</td>
                </tr>
            </ng-template>
        </p-table>
    </div>

    <div class="pagination_wrapper flex align-items-center flex justify-content-between" *ngIf="allow_Pagination">
        <div class="left flex align-items-center pl-3">
            <p>No. of entries</p>
            <p-paginator [showCurrentPageReport]="false" [rows]="10" [totalRecords]="totalRecords"
                [rowsPerPageOptions]="[10, 25, 50, 100, 250, 350, 500]" (onPageChange)="onPageSizeChange($event)"
                #p></p-paginator>
        </div>
        <div class="middle">
            <p class="fs-14 fw-400 font-poppin" style="margin-left: -8vw;">Showing {{currentPageReport?.first}} - {{currentPageReport?.last}} of {{totalRecords}} entries</p>
        </div>
        <div class="right flex align-items-center">
            <p>Page {{firstPage}} of {{lastPage}}</p>
            <p-paginator [showCurrentPageReport]="false" [rows]="pagesize_selected" [totalRecords]="lastPage == 1 ? 0 : totalRecords"
                [rowsPerPageOptions]="[10, 25, 50, 100, 250, 350, 500]" (onPageChange)="onPageChange($event)"
                #p></p-paginator>
        </div>

    </div>
</div>