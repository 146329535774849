<div class="grid_tabs mb-2 pl-4" *ngIf="grid_tabs.length > 0">
    
    <p-tabView (onChange)="onTabChange($event)" [activeIndex]="selectedTabIndex" *ngIf="tab_type == 'inquiry_grid'" [scrollable]="grid_tabs.length > 10 ? true : false">
        <ng-container *ngFor="let tab of grid_tabs; let i = index">
            <p-tabPanel [header]="tab.label" [disabled]="tab.disabled"></p-tabPanel>
        </ng-container>
    </p-tabView>

    <div class="flex gap-3" *ngIf="tab_type == 'button'">
        <ng-container *ngFor="let tab of grid_tabs; let i = index">
            <button class="tab-btn" [ngClass]="selectedTabIndex == i ? 'active' : ''" (click)="onTabChange({index: i})">{{tab.label}} | {{tab?.count ? tab.count : 0}}</button>
        </ng-container>
    </div>
    

    <p-tabView [styleClass]="'formTabs'" [activeIndex]="selectedTabIndex" (onChange)="onTabChange($event)" *ngIf="tab_type == 'form-tab'" [scrollable]="grid_tabs.length > 10 ? true : false">
        <p-tabPanel [header]="tab.label" [leftIcon]="'i-check'"
        *ngFor="let tab of grid_tabs; let i = index"></p-tabPanel>
    </p-tabView>
</div>