import { Component, EventEmitter, Input, OnInit, Output, ViewChild, ChangeDetectorRef  } from '@angular/core';
import { MenuItem } from 'primeng/api';
import { Paginator } from 'primeng/paginator';
import { HighlighterPipe } from 'src/app/core/pipes/highlighter.pipe';
import { GridCountDialogComponent } from './components/grid-count-dialog/grid-count-dialog.component';

@Component({
  selector: 'acv-grid',
  templateUrl: './acv-grid.component.html',
  styleUrls: ['./acv-grid.component.scss']
})
export class ACVGridComponent implements OnInit {


  grid_columns: any;
  grid_data: any;
  active_row_data:any;
  allow_selection: boolean = false;
  allow_Headerselection: boolean = true;
  allow_sorting: boolean = false;
  reorderableColumns: boolean = false;
  allow_columnReOrder: boolean = false;
  allow_Pagination: boolean = true;
  show_livelevelActions: boolean = true;
  show_EditIcon: boolean = false;
  show_DeleteIcon: boolean = false;
  show_AttachmentIcon: boolean = false;
  show_RowGroup: boolean = false;
  freeze_actionColumn: boolean = false;
  selectedRecords: any;
  line_levelActions: MenuItem[] = [];
  hold_gridSorting: boolean = false;
  recordCountPerPage: any = [];
  totalRecords: any = [];
  highlightedSearchItems: any = '';
  allowHighlightSearching: boolean = false;
  currentHoveredCellId: string = '';
  lastPage: any = '';
  firstPage: any = '';
  currentPageReport: any;
  pagesize_selected:number = 10;

  @Output() SelectionChange = new EventEmitter();
  @Output() AllSelect = new EventEmitter();

  @Input('GridColumns') set _GridColumns(value: any) {

    this.grid_columns = (value) ? value : [];
  }

  @Input('GridDataSource') set _GridDataSource(value: any) {

    this.grid_data = (value) ? value : [];
  }

  @Input('AllowSelection') set _AllowSelection(value: any) {

    this.allow_selection = (value) ? value : false;
  }

  @Input('AllowHeaderSelection') set _AllowHeaderSelection(value: any) {

    this.allow_Headerselection = (value == false) ? value : true;
  }

  @Input('AllowSorting') set _AllowSorting(value: any) {

    this.allow_sorting = (value) ? value : false;
  }

  @Input('AllowColumnReOrdering') set _AllowColumnReOrdering(value: any) {

    this.reorderableColumns = (value) ? value : false;
  }

  @Input('AllowPagination') set _AllowPagination(value: any) {

    this.allow_Pagination = (value) ? value : false;
  }

  @Input('ShowLineLevelAction') set _ShowLineLevelAction(value: any) {

    this.show_livelevelActions = (value) ? value : false;
  }

  @Input('LineLevelActionsList') set _LineLevelActions(value: any) {

    if (value) {
      value.forEach((item:any) => {
        this.line_levelActions.push({label: item.label, disabled: item?.disabled, command: e => this.onLineAction(e, null)}); 
      });
      //this.line_levelActions = value;
    }
    else {
      this.line_levelActions = [];
    }
  }

  @Input('ShowEditIcon') set _ShowEditIcon(value: any) {

    this.show_EditIcon = (value) ? value : false;
  }

  @Input('ShowDeleteIcon') set _ShowDeleteIcon(value: any) {

    this.show_DeleteIcon = (value) ? value : false;
  }

  @Input('ShowAttachmentIcon') set _ShowAttachmentIcon(value: any) {

    this.show_AttachmentIcon = (value) ? value : false;
  }

  @Input('FreezeActionColumn') set _FreezeActionColumn(value: any) {

    this.freeze_actionColumn = (value) ? value : false;
  }

  @Input('ShowRowGroup') set _ShowRowGroup(value: any) {

    this.show_RowGroup = (value) ? value : false;
  }

  @Input('RecordCountPerPage') set _RecordCountPerPage(value: any) {

    this.recordCountPerPage = (value) ? value : 0;
  }

  @Input('TotalRecords') set _TotalRecords(value: any) {

    this.totalRecords = (value) ? value : 0;
  }
  @Input('HighlightedSearchItems') set _HighlightedSearchItems(value: any) {

    this.highlightedSearchItems = (value) ? value : '';
  }
  @Input('AllowHighlightSearching') set _AllowHighlightSearching(value: any) {

    this.allowHighlightSearching = (value) ? value : false;
  }

  @Input('LastPage') set _LastPage(value: any) {
    this.lastPage = (value) ? value : '';
  }

  @Input('FirstPage') set _FirstPage(value: any) {
    this.firstPage = (value) ? value : '';
  }

  @Input('currentPageReport') set _CurrentPageReport(value: any) {
    this.currentPageReport = (value) ? value : {};
    //console.log(value);
  }

  @ViewChild('p', { static: false }) paginator: Paginator;
  @ViewChild('ACV_GridCountDialog_Ref') ACV_GridCountDialog_Ref: GridCountDialogComponent;

  @Output() RowSelection: EventEmitter<any> = new EventEmitter();
  @Output() PageChange: EventEmitter<any> = new EventEmitter();
  @Output() SortChange: EventEmitter<any> = new EventEmitter();
  @Output() GridAction: EventEmitter<any> = new EventEmitter();
  @Output() cellClicked: EventEmitter<any> = new EventEmitter();

  constructor(private highlighter: HighlighterPipe, private cdr: ChangeDetectorRef) { }

  ngOnInit() { }

  onSortChange(evnt: any) {
    this.SortChange.emit(evnt);
  }

  onPageSizeChange(evnt: any) {
    this.pagesize_selected = evnt.rows;
    this.PageChange.emit(evnt);
  }

  onPageChange(evnt: any) {
    evnt.rows = this.pagesize_selected;
    this.PageChange.emit(evnt);
  }

  onSelectAll(evnt: any) {
    this.AllSelect.emit(evnt);
  }

  onSelectionChange(evnt: any) {
    this.SelectionChange.emit(evnt);
  }

  getLineActionItems(row_data:any){
    if(row_data?.status == "Draft"){
      
      return this.line_levelActions.filter((x:any) => (x?.label?.toLowerCase() !== 'active' && x?.label?.toLowerCase() !== 'inactive'));
    }
    else{
      let status_action_index = this.line_levelActions.findIndex((x:any) => x?.label?.toLowerCase() == 'active' || x?.label?.toLowerCase() == 'inactive');
      if(status_action_index !== -1){
        this.line_levelActions[status_action_index].label = (row_data?.status?.toLowerCase() == 'active') ? 'Inactive' : 'Active';
      }
      
      return this.line_levelActions;
    }
  }


  setHoveredItem(cellId: string) {
    this.currentHoveredCellId = cellId;
  }

  clearHoveredItem() {
    this.currentHoveredCellId = '';
  }

  onGridAction(act: string, actiondata?: any) {
    let actionObj = { action: act, data: actiondata };
    this.GridAction.emit(actionObj);
  }

  onLineAction(evnt: any, row_data: any) {
    if (evnt.item.label == "Item notes") {
      this.onGridAction("ItemNotes", {});
    }
    else {
      if (evnt.item.label.toLowerCase() == "inactive" || evnt.item.label.toLowerCase() == "active") {
        this.onGridAction(this.active_row_data?.status.toLowerCase() == "inactive" ? "Active" : "InActive", this.active_row_data);
      }
      else {
        this.onGridAction(evnt.item.label, this.active_row_data);
      }
    }
  }

  oncellClicked(evnt: any) {
    evnt && this.cellClicked.emit(evnt);
  }

  onCountClick(col: any, data:any) {
    this.ACV_GridCountDialog_Ref.data = data[col?.field];
    this.ACV_GridCountDialog_Ref.gridColumns = col?.count_GridColumns ? col.count_GridColumns : [];
    this.ACV_GridCountDialog_Ref.data_type = col?.dataType ? col.dataType : "list";
    this.ACV_GridCountDialog_Ref.title = col?.dialogTitle ? col.dialogTitle : col?.header;
    this.ACV_GridCountDialog_Ref.show();
  }

  columnReorderToggle() {
    this.allow_columnReOrder = !this.allow_columnReOrder;
    this.hold_gridSorting = !this.hold_gridSorting;
    this.grid_columns = [...this.grid_columns];
  }

  clearSelection() {
    this.selectedRecords = [];
  }

  resetPagination() {
    this.paginator.changePageToFirst(1);
  }
}
