import { ChangeDetectorRef, Component, ElementRef } from '@angular/core';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { NgxSpinnerService } from 'ngx-spinner';
import { ApiHttpService } from 'src/app/core/services/apiHttpcall.service';
import { ApienvService } from 'src/app/core/services/apienv.service';
import { DataResolverService } from 'src/app/core/services/data-resolver.service';
import { PermissionsService } from 'src/app/core/services/permissions.service';
import { ProfileService } from 'src/app/core/services/profile.service';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss'],
})
export class LoginComponent {
  IsLoading: boolean = false;
  hide: boolean = true;
  resp: any = undefined;
  rememberMe = false;

  submitted: boolean = false;
  loginError: string = '';
  loginForm: FormGroup = new FormGroup({});

  constructor(
    private router: Router,
    private httpService: ApiHttpService,
    private ApienvService: ApienvService,
    private profileService: ProfileService,
    private el: ElementRef,
    private UILoader: NgxSpinnerService,
    private dataResolver: DataResolverService,
    private cdr: ChangeDetectorRef,
    private permissionsService: PermissionsService
  ) {}

  ngOnInit() {
    this.initializeForm();

    this.cdr.detectChanges();
  }

  initializeForm() {
    this.loginForm = new FormGroup({
      email: new FormControl('', [Validators.required]),
      password: new FormControl('', [Validators.required]),
      remember_me: new FormControl(false, [Validators.required]),
    });
  }


  async handleSubmit() {
    this.UILoader.show();
    this.submitted = true;
    this.focusIN();
    if (
      this.loginForm.get('email')?.valid &&
      this.loginForm.get('password')?.valid
    ) {
      this.IsLoading = true;
      // this.cdr.detectChanges();
      // // this payload needs to be send on the server.
      this.resp = await this.httpService.post('/login', this.loginForm.value);
      if (this.resp.status == 200) {
        this.profileService.settoken(this.resp?.data?.original.access_token);
        if(this.resp?.data?.original?.user?.permissions){
          let response = JSON.parse(this.resp?.data?.original?.user?.permissions);
          localStorage.setItem(
            'user_permissions',
            JSON.stringify(this.permissionsService.transform(response))
          );
          
          this.profileService.setPermissions();
        }
        // this.UILoader.hide();
        this.router.navigate(['/setup/services/list']);
      } else {
        this.IsLoading = false;
        this.submitted = false;
        this.loginError = this.resp.error;
      }
    }
  }

  focusIN() {
    const invalidControl = this.el.nativeElement.querySelector('input');
    if (invalidControl) {
      invalidControl.focus();
    }
    this.resp = undefined;
  }
}
