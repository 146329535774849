<add-select-item-dialog #AddItemDialog_Ref (onSuccess)="reloadLookup()"></add-select-item-dialog>

<div class="input-elem" [formGroup]="form">
    <p class="text-control-label mb-2">{{ControlLabelText}} <sup *ngIf="Required">*</sup> </p>
    <p-dropdown #acvdropdown [formControlName]="ControlName" [options]="options_list" [optionLabel]="OptionLabel"
        [optionValue]="OptionValue" [filter]="Filter" [filterBy]="OptionLabel" [showClear]="showClear"
        filterPlaceholder="Search" [disabled]="Disabled" [placeholder]="PlaceHolderText" [appendTo]="'body'"
        [dropdownIcon]="IsLoading ? 'pi pi-spinner pi-spin' : 'pi pi-chevron-down'"
        [overlayOptions]="{styleClass: 'acv-select-overlayPanelClass'}"
        [ngClass]="{'select-input-error' : !form.controls[ControlName].valid && IsSubmited}" (onChange)="onSelect($event)">

        <ng-template pTemplate="filter" *ngIf="AddNewItem">
            <span class="p-input-icon-left w-full acv-select-filterinput brd-b" *ngIf="Filter">
                <i class="pi pi-search"></i>
                <input type="text" [(ngModel)]="filter_string" [ngModelOptions]="{standalone: true}" pInputText
                    placeholder="Search" (input)="filterDropdown($event)">
            </span>
            <div class="custom-text pl-3 pt-3">
                <p class="font-poppin fw-400 fs-14 cursor-pointer" style="color: var(--primary-bg);"
                    (click)="onAddNewItem()"><i class="pi ic-plus"></i>
                    {{AddNewItemText}}</p>
            </div>
        </ng-template>

        <ng-template pTemplate="selectedItem" *ngIf="form.controls[ControlName].value">
            <div>{{ getSelectedLabel() }}</div>
        </ng-template>

        <ng-template let-item let-index="rowIndex" pTemplate="item">
            <div>{{ item[OptionLabel] }}</div>
        </ng-template>

    </p-dropdown>
    <div class="mt-1 text-right"
        [ngClass]="{'flex justify-content-between' : !form.controls[ControlName].valid && IsSubmited}">
        <p class="fs-10 fw-400 font-poppin text-color-red" *ngIf="!form.controls[ControlName].valid && IsSubmited">
            Please fill this required field</p>
    </div>
</div>