import { animate, state, style, transition, trigger } from '@angular/animations';
import { Component, Input, Output, OnInit, EventEmitter } from '@angular/core';
import { Location } from '@angular/common';

@Component({
  selector: 'page-header',
  templateUrl: './page-header.component.html',
  styleUrls: ['./page-header.component.scss'],
  animations: [trigger('slideInOut', [
    state('show', style({
      width: '150px'
    })),
    state('hide', style({
      opacity: '0',
      width: '0px'
    })),
    transition('show => hide', animate('400ms ease-in-out')),
    transition('hide => show', animate('400ms ease-in-out'))
  ])]
})
export class PageHeaderComponent implements OnInit {

  headerActions: any;
  only_LongSearch: Boolean = false;
  LongSearchValue: any;
  @Input('title') _title: any;
  @Input('detailText') _detailText: any;
  @Input('breadCrumbs') _breadCrumbs: any;
  @Input('show_create') show_create: any = true;
  show_backButton: boolean = true;


  @Input('onlyLongSearch') set _onlyLongSearch(value: any) {

    this.only_LongSearch = (value) ? value : false;
  }

  @Input('headerActions') set _headerActions(value: any) {

    this.headerActions = value;
  }

  @Input('AllowGoBack') set _AllowGoBack(value: any) {

    this.show_backButton = value;
  }

  @Output() onActionSelect = new EventEmitter<any>();
  @Output('longSearchChange') _longSearchChange = new EventEmitter<any>();

  

  toggle_SearchBar: string = "hide";
  

  constructor(private _location: Location) { }

  ngOnInit() {
    if(this._title.includes('Create')){
      if(this.headerActions?.length){
        this.headerActions.forEach((action:any) => {
          action.value = true;
        });
      }
      
    }
 
  }

  ActionSelection(action: any) {
    this.onActionSelect.emit(action);
  }

  toggleSearchBar() {
    //alert("yes");
    this.toggle_SearchBar = (this.toggle_SearchBar == "hide") ? "show" : "hide";
  }

  goBack() {
    this._location.back();
  }
  
  onlongSearchChange(val: any) {
    if (val.length >= 2 || val.length == 0) {
      this._longSearchChange.emit(val);
    }

  }
}
