<p-dialog [styleClass]="'popup_import'" [draggable]="false" [closable]="false" [style]="{'width': '30%'}" [modal]="true"
    [visible]="show_Dialog">

    <ng-template pTemplate="header">
        <div class="flex wd-100 dialog_header_wrapper align-items-center justify-content-between ng-star-inserted">
            <span class="text-xl font-bold">{{title}}</span>
            <p-button icon="pi pi-times" (onClick)="hide()"
                styleClass="p-button-rounded p-button-danger p-button-text p-button-raised"></p-button>
        </div>
    </ng-template>

    <div class="import_content_wrapper">
        <acv-grid *ngIf="data_type == 'grid'" [GridColumns]="gridColumns" [GridDataSource]="data"
            [AllowSelection]="false" [AllowSorting]="false" [AllowPagination]="false" [AllowColumnReOrdering]="false"
            [ShowLineLevelAction]="false"></acv-grid>

        <ng-container *ngIf="data_type == 'list'">
            <p class="fw-500 fs-14 font-poppin mt-4 mb-4" *ngFor="let item of data">{{item}}</p>
        </ng-container>

    </div>
</p-dialog>