import { Component } from '@angular/core';

@Component({
  selector: 'app-history',
  templateUrl: './history.component.html',
  styleUrls: ['./history.component.scss']
})
export class HistoryComponent {

  visibility: boolean = false;
  events: any;
  constructor() {
    this.events = [ [
      { title: 'Today' },
      { title: '12:15:12 AM (CST) Order shipped having Qty 15', sub_title: 'EDI', status: 'Shipped', color: '#4FDF89' },
      { title: '10:02:10 AM (CST) In Packing having Qty 15', sub_title: 'EDI', status: 'In Packing', color: '#FBCE50' },
      { title: '8:15:10 AM (CST) In Picking having Qty 15', sub_title: 'EDI', status: 'In Picking', color: '#FBCE50' },
    ],[
      { title: 'Yesterday' },
      { title: '7:08:08 PM (CST) Shipment created having qty 15', sub_title: 'Zack Wong', status: 'Open', color: '#FBCE50' },
      { title: '6:08:08 PM (CST) Payment checked having qty 15', sub_title: 'Zack Wong', status: 'On Hold', color: '#4FB4DF' },
      { title: '6:08:08 PM (CST) Order unvoided having qty 15', sub_title: 'Zack Wong', status: 'Unvoid', color: '#4FB4DF' },
      { title: '6:08:08 PM (CST) Order voided having qty 15', sub_title: 'Zack Wong', status: 'Void', color: '#4FB4DF' },
    ]
  ]
}
  show(){
  this.visibility = true;
  console.log('demo', this.events);
  }

  hide(){
  this.visibility = false;
  }
}
