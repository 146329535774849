<side-filters #side_Filters></side-filters>

<div class="grid align-items-center m-0 pl-1 pl-3 pr-3">
    <div [ngClass]="only_LongSearch ? 'md:col-2 lg:col-2 xl:col-2 col-12 align-items-center flex gap-3' : 'md:col-4 lg:col-3 xl:col-3 col-12 align-items-center flex gap-3'">
        <i class="pi ic-back-arrow back-arrow" *ngIf="show_backButton" (click)="goBack()"></i>
        <div>
            <p-breadcrumb [model]="_breadCrumbs"></p-breadcrumb>
            <h3 class="page_title">{{_title}}</h3>
        </div>
        
    </div>
    <div class="col-12" [ngClass]="only_LongSearch ? 'md:col-10 lg:col-10 xl:col-10' : 'md:col-8 lg:col-9 xl:col-9'">
        <div class="flex align-items-center" [ngClass]="only_LongSearch ? 'justify-content-between' : 'justify-content-end'">
            <ng-container *ngFor="let action of headerActions">
                <div class="btn_group flex align-items-center" *ngIf="action.type == 'button-group'">
                    <ng-container>
                        <span [@slideInOut]="toggle_SearchBar" class="p-input-icon-left search_bar">
                            <i class="pi i-search"></i>
                            <input type="text" #SearchBarInput [(ngModel)]="LongSearchValue" (ngModelChange)="onlongSearchChange(LongSearchValue)" (blur)="toggleSearchBar()" [ngStyle]="{'width': toggle_SearchBar == 'hide' ? '0px' : '160px'}" pInputText placeholder="Search" />
                        </span>
                    </ng-container>
                    <ng-container *ngFor="let group_btn of action.btnGroupItems">
                        
                        <p-button type="button" *ngIf="toggle_SearchBar == 'hide' && group_btn.IsVisible && group_btn.iconClass == 'i-search'"
                        (onClick)="toggleSearchBar(); SearchBarInput.focus();"
                        class="p-button-text {{group_btn.cssClass}}" 
                        [label]="group_btn?.label"
                        icon="pi {{group_btn.iconClass}}"></p-button>
                        
                        <p-button type="button"
                        (onClick)="ActionSelection(group_btn)"
                        *ngIf="group_btn.IsVisible && group_btn.iconClass !== 'i-search'" 
                        class="p-button-text {{group_btn.cssClass}}"
                        icon="pi {{group_btn.iconClass}}"
                        [label]="group_btn?.label"
                        [badge]="group_btn?.badge ? group_btn?.badge.value : ''"
                        [badgeClass]="'p-badge-danger'"
                        ></p-button>
                        <!-- <p-badge *ngIf="group_btn.IsVisible && group_btn?.badge" [value]="group_btn?.badge.value" [severity]="group_btn?.badge.severity"></p-badge> -->

                    </ng-container>
                </div>

                <div *ngIf="action.type == 'search-bar'">
                    <span class="p-input-icon-left grid_searchBox">
                        <i class="pi i-search"></i>
                        <input type="text" class="search_box" [(ngModel)]="LongSearchValue" (ngModelChange)="onlongSearchChange(LongSearchValue)" pInputText placeholder="Search" />
                    </span>
                </div>

                
                <div *ngIf="action.type == 'regular-button' && show_create">
                    <!-- <p-button type="button" label="{{action.label}}" *ngIf="action.isVisible" class="p-button-text {{action.cssClass}}" icon="pi {{action.iconClass}}"></p-button> -->
                    <p-button type="button" 
                    [disabled]="action.isDisabled"
                    (onClick)="ActionSelection(action)"
                    label="{{action.label}}" 
                    icon="pi {{action.iconClass}}"
                    *ngIf="action.isVisible" class="p-button-text elevated-btn primary {{action.cssClass}}"></p-button>
                </div>

                <div *ngIf="action.type == 'setting-button'">
                    <button pButton pRipple type="button" label="" 
                    icon="pi i-setting-pro" 
                    (click)="ActionSelection(action)"
                    class="p-button-raised p-button-text p-button-plain button-notify"></button>
                </div>

                <div *ngIf="action.type == 'split-button'">
                    <p-button type="button" 
                    (onClick)="actions_overlay.toggle($event);"
                    [label]="action.label" 
                    [icon]="action.iconClass"
                    *ngIf="action.isVisible" class="p-button-text header_elevated-btn primary"></p-button>

                    <p-overlayPanel #actions_overlay [dismissable]="true" styleClass="header-actions-overlay">
                        <ng-template pTemplate>
                            <div class="action-MenuItems flex justify-content-between" 
                            *ngFor="let actionItem of action.splitButtonItems" (click)="actions_overlay.toggle($event); ActionSelection(actionItem)">
                                <p>{{actionItem.label}}</p>
                                <p>{{actionItem?.count}}</p>
                            </div>
                        </ng-template>
                    </p-overlayPanel>
                </div>

                <div class="flex align-items-center gap-3 header_toggle" *ngIf="action.type == 'toggle-button'">
                    <p class="fs-14 fw-400 font-poppin text-color-black">{{action.value ? 'Active' : 'Inactive' }}</p>
                    <p-inputSwitch name="acv-switch" [(ngModel)]="action.value" styleClass="hearder_switchToggle" (onChange)="ActionSelection(action)"></p-inputSwitch>
                </div>

                <div class="flex align-items-center gap-2 header_regularLabel" (click)="ActionSelection(action)" *ngIf="action.type == 'regular-label'">
                    <i class="pi {{action.iconClass}}"></i>
                    <p>{{action.label}}</p>
                </div>

            </ng-container>
            
        </div>
    </div>
</div>
