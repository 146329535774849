import { Component, EventEmitter, Output } from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';
import { ConfirmationService, MessageService } from 'primeng/api';
import { DialogService } from 'primeng/dynamicdialog';
import { ApiHttpService } from 'src/app/core/services/apiHttpcall.service';

@Component({
  selector: 'add-select-item-dialog',
  templateUrl: './add-select-item-dialog.component.html',
  styleUrls: ['./add-select-item-dialog.component.scss']
})
export class AddSelectItemDialogComponent {

  title: string = "";
  show_Dialog: boolean = false;
  dialog_header: string = "";
  data: any[] = [];
  addItemForm: any;
  is_form_submit:boolean = false;
  api_url:string;
  isLoading:boolean = false;

  @Output() onSuccess: EventEmitter<any> = new EventEmitter();

  constructor(public dialogService: DialogService, private fb: FormBuilder, 
    private httpService: ApiHttpService, private messageService: MessageService, private confirmationService: ConfirmationService,) { }

  // cancel upload
  uploadCancel() {

  }

  initializeForm() {
    this.addItemForm = this.fb.group({
      name: ['', Validators.compose([Validators.required])],
    });
  }

  // cancel popup 
  cancel() {
    this.dialogService.dialogComponentRefMap.forEach(dialog => {
      dialog.destroy();
    });
  }

  show() {
    this.show_Dialog = true;
    this.initializeForm();
  }

  hide() {
    this.is_form_submit = false;
    this.show_Dialog = false;
  }

  async handleSubmitForm(){
    this.is_form_submit = true;

    if(this.addItemForm.valid){
      this.isLoading = true;
      let resp = await this.httpService.post(this.api_url, this.addItemForm.value);

      if(resp?.status == 201){
        this.onSuccess.emit();
        this.is_form_submit = false;
        this.hide();
      }
      else{
        this.messageService.add({ severity: 'error', summary: 'Error', detail: resp?.error?.message, key: 'app_notiy' });
      }
      this.isLoading = false;
    }
  }

  handleCancelConfirm() {
    if(this.addItemForm.controls['name'].value)
      {
      this.confirmationService.confirm({
        message: 'All the saved data will be lost. Are you sure?',
        header: 'Cancel',
        icon: 'i-warn',
        acceptLabel: 'Yes',
        rejectLabel: 'No',
        accept: () => {
          this.hide();
        },
      });
    }
    else{
      this.hide();
    }
    
  }
}
