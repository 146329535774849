<div class="input-elem" [formGroup]="form">
  <p class="text-control-label mb-2">
    {{ ControlLabelText }} <sup *ngIf="Required">*</sup>
  </p>
  <span class="flex flex-column" [ngClass]="
      IconClass && IconPosition == 'left'
        ? 'p-input-icon-left'
        : IconClass && IconPosition == 'right'
        ? 'p-input-icon-right'
        : ''
    ">
    <i class="pi {{ IconClass }}" *ngIf="IconClass"></i>

    <input *ngIf="ControlType == 'text'" #textControl [formControlName]="ControlName" [type]="ControlType"
      [placeholder]="PlaceHolderText" [maxlength]="ExemptMaxLength ? null : characterLimit" [ngClass]="{
        'input-error': !form.controls[ControlName].valid && IsSubmited
      }" pInputText (keyup)="wordCounter()" />

    <p-chips *ngIf="ControlType == 'text-chip'" formControlName="values" [formControlName]="ControlName"
      [placeholder]="PlaceHolderText" [allowDuplicate]="false">
      <ng-template let-item pTemplate="item"> {{ item }} </ng-template>
    </p-chips>

    <!-- <input *ngIf="ControlType == 'text-chip'" #textControl [formControlName]="ControlName" [type]="ControlType"
      [placeholder]="PlaceHolderText" [maxlength]="ExemptMaxLength ? null : characterLimit" [ngClass]="{
        'input-error': !form.controls[ControlName].valid && IsSubmited
      }" pInputText (keyup)="wordCounter()" /> -->

    <p-inputMask mask="(999) 999-9999" *ngIf="ControlType == 'phone'" [formControlName]="ControlName" [ngClass]="{
        'mask-input-error': !form.controls[ControlName].valid && IsSubmited
      }" placeholder="(000) 000-0000"></p-inputMask>

    <!-- phoneCode Start -->

    <div class="customPhoneInput flex align-items-center gap-2 justify-content-start" [ngClass]="{
        'custom-input-error': !form.controls[ControlName].valid && IsSubmited
      }" *ngIf="ControlType == 'phoneCode'">
      <p-dropdown class="codeDropdown" [formControlName]="ControlNameCode" [options]="cc_list"
        optionLabel="phonecode" (onChange)="onPhoneCodeChange($event)" optionValue="phonecode" styleClass="acv-phonecode"
        [placeholder]="PlaceHolderTextCode" [overlayOptions]="{ styleClass: 'phonecode-overlayPanelClass' }" [appendTo]="'body'" [dropdownIcon]="
          IsLoading ? 'pi pi-spinner pi-spin' : 'pi pi-chevron-down'
        ">
        <ng-template pTemplate="selectedItem" *ngIf="form.controls[ControlNameCode].value">
          <div class="flex gap-2 align-items-center">
            <img style="width: 20px; height: 20px" src="https://flagsapi.com/{{
                getSelectedCountry(form.controls[ControlNameCode].value)?.code
              }}/shiny/32.png" />
            <div>{{ form.controls[ControlNameCode].value }}</div>
          </div>
        </ng-template>

        <ng-template let-item let-index="rowIndex" pTemplate="item">
          <div class="flex gap-2 align-items-center">
            <img alt="{{ item.name }}" style="width: 20px; height: 20px"
              src="https://flagsapi.com/{{ item.code }}/shiny/32.png" />
            <div class="fontstyle-phonecode">{{ item.phonecode }}</div>
          </div>
        </ng-template>
      </p-dropdown>
      <p-inputMask mask="(999) 999-9999" class="codeInput" [formControlName]="ControlName"
        placeholder="(000) 000-0000"></p-inputMask>
    </div>

    <!-- phoneCode End -->

    <!-- <p-inputGroup
      class="flex justify-content-between"
      *ngIf="ControlType == 'phoneCod'"
    >
      <p-inputGroupAddon>
        <p-dropdown
          [formControlName]="ControlNameCode"
          [options]="options_list"
          [optionLabel]="OptionLabel"
          (onChange)="onPhoneCodeChange($event)"
          [optionValue]="OptionValue"
          [placeholder]="PlaceHolderTextCode"
          [appendTo]="'body'"
          [dropdownIcon]="
            IsLoading ? 'pi pi-spinner pi-spin' : 'pi pi-chevron-down'
          "
        >
          <ng-template
            pTemplate="selectedItem"
            *ngIf="form.controls[ControlNameCode].value"
          >
            <div class="flex gap-2 align-items-center">
              <img
                style="width: 20px; height: 20px"
                src="https://flagsapi.com/{{
                  getSelectedCountry(form.controls[ControlNameCode].value)?.code
                }}/shiny/32.png"
              />
              <div>{{ form.controls[ControlNameCode].value }}</div>
            </div>
          </ng-template>

          <ng-template let-item let-index="rowIndex" pTemplate="item">
            <div class="flex gap-2 align-items-center">
              <img
                alt="{{ item.name }}"
                style="width: 20px; height: 20px"
                src="https://flagsapi.com/{{ item.code }}/shiny/32.png"
              />
              <div>{{ item[OptionLabel] }}</div>
            </div>
          </ng-template>
        </p-dropdown>
      </p-inputGroupAddon>
      <p-inputMask
        mask="(999) 999-9999"
        class="custom-width"
        [formControlName]="ControlName"
        [ngClass]="{
          'mask-input-error': !form.controls[ControlName].valid && IsSubmited
        }"
        placeholder="(000) 000-0000"
      ></p-inputMask>
    </p-inputGroup> -->

    <p-calendar *ngIf="ControlType == 'year'" [formControlName]="ControlName" [placeholder]="PlaceHolderText"
      [inputStyleClass]="
        !form.controls[ControlName].valid && IsSubmited ? 'input-error' : ''
      " view="year" dateFormat="yy" />

    <p-calendar *ngIf="ControlType == 'datePicker' || ControlType == 'date-time-picker'" [formControlName]="ControlName"
      [placeholder]="PlaceHolderText" [showTime]="ControlType == 'datePicker' ? false : true" [inputStyleClass]="
        !form.controls[ControlName].valid && IsSubmited ? 'input-error' : ''
      " />

    <p-inputNumber [formControlName]="ControlName" *ngIf="ControlType == 'currency'" inputId="currency-us"
      mode="currency" currency="USD" locale="en-US" styleClass="w-full" [inputStyleClass]="
        !form.controls[ControlName].valid && IsSubmited ? 'input-error' : ''
      "></p-inputNumber>

    <p-inputNumber [formControlName]="ControlName" *ngIf="ControlType == 'decimal'" mode="decimal"
      [maxFractionDigits]="2" styleClass="w-full" [placeholder]="PlaceHolderText" [inputStyleClass]="
        !form.controls[ControlName].valid && IsSubmited ? 'input-error' : ''
      "></p-inputNumber>

    <p-calendar [formControlName]="ControlName" *ngIf="ControlType == 'date'" [inputStyleClass]="
        !form.controls[ControlName].valid && IsSubmited ? 'input-error' : ''
      " [placeholder]="PlaceHolderText" [minDate]="minDate" [maxDate]="maxDate" [selectionMode]="selectionMode" />

    <div *ngIf="ControlType == 'radio'" class="flex gap-3 mt-3">
      <div *ngFor="let radio of radioList" class="field-checkbox">
        <p-radioButton [inputId]="radio.key" [value]="radio.key" [formControlName]="ControlName" />
        <label [for]="radio.key" class="ml-2">
          {{ radio.name }}
        </label>
      </div>
    </div>
  </span>

  <div class="mt-1 text-right" [ngClass]="{
      'flex justify-content-between':
        !form.controls[ControlName].valid && IsSubmited
    }">
    <p class="fs-10 fw-400 font-poppin text-color-red" *ngIf="!form.controls[ControlName].valid && IsSubmited">
      <span *ngIf="form.controls[ControlName]?.errors?.['required']">Please fill this required field</span>
      <span
        *ngIf="form.controls[ControlName]?.errors?.['incorrect']">{{form.controls[ControlName]?.errors?.['message']}}</span>
      <span *ngIf="form.controls[ControlName]?.errors?.['invalidLatLong']">Invalid latitude and longitude format.</span>
      <span *ngIf="form.controls[ControlName]?.errors?.['pattern']">{{
        PatternErrorMessage ? PatternErrorMessage : "Invalid Email Address"
        }}</span>
    </p>
    <p id="word-count" *ngIf="ShowWordCount" [ngClass]="words > characterLimit ? 'text-danger' : ''">
      {{ words }}/{{ characterLimit }} Characters
    </p>
  </div>
</div>