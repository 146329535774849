import { Injectable } from '@angular/core';
import { Permissions } from './permission.interface';

@Injectable({
  providedIn: 'root',
})
export class PermissionsService {
  constructor() {}

  transform(input: any[]): Permissions {
    const result: any = {};

    input.forEach((category) => {
      const categoryName = category.name.replace(/\s+/g, '');
      result[categoryName] = {};

      category.items.forEach((item: any) => {
        const itemName = item.name.replace(/\s+/g, '');
        result[categoryName][itemName] = {
          edit: item.edit,
          create: item.create,
          view: item.view,
          inactive: item.inactive,
        };
      });
    });

    return result as Permissions;
  }
}
